import React from "react";
import { Margin } from "styled-components-spacing";
import Layout from "../components/Layout";
import Section from "../components/Section";
import Link from "../components/Link";
import Heading from "../components/Heading";

const NotFoundPage = () => (
  <Layout>
    <Section>
      <Margin bottom={3}>
        <Heading size={1}>Page not found</Heading>
      </Margin>
      <Link to="/">Go back to the home page</Link>
    </Section>
  </Layout>
);

export default NotFoundPage;
